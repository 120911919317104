import { Color, PaletteMode } from "@mui/material";
import { blue, green, grey, red, yellow } from "./palettes";
import { fill } from "lodash";

declare module "@mui/material/styles" {
  interface Theme {
    extraPalette: {
      blue: Partial<Color>;
      yellow: Partial<Color>;
      red: Partial<Color>;
      green: Partial<Color>;
    };
  }
  interface ThemeOptions {
    extraPalette: {
      blue: Partial<Color>;
      yellow: Partial<Color>;
      red: Partial<Color>;
      green: Partial<Color>;
    };
  }
}

const spacing_1 = 4;
const spacing_2 = 8;
const spacing_3 = 12;
const spacing_4 = 16;
const spacing_5 = 24;
const spacing_6 = 32;
const spacing_7 = 40;
const spacing_8 = 56;
const spacing_9 = 64;
const spacing_10 = 80;

export const getDesignTokens = (mode: PaletteMode) => ({
  spacing: [
    0,
    spacing_1,
    spacing_2,
    spacing_3,
    spacing_4,
    spacing_5,
    spacing_6,
    spacing_7,
    spacing_8,
    spacing_9,
    spacing_10,
  ],
  palette: {
    mode,
    ...(mode === "light"
      ? {
          primary: {
            main: "#000",
            dark: "#000",
            light: "#2c2c2c",
            contrastText: "#fff",
          },
          secondary: {
            main: "#ff4843",
            dark: "#c4001a",
            light: "#ff7e6f",
            contrastText: "#fff",
          },
          error: {
            main: "#d32f2f",
            dark: "#c62828",
            light: "#ef5350",
            contrastText: "#fff",
          },
          warning: {
            main: "#ed6c02",
            dark: "#e65100",
            light: "#ff9800",
            contrastText: "rgba(0, 0, 0, 0.87)",
          },
          info: {
            main: "#0288d1",
            dark: "#01579b",
            light: "#03a9f4",
            contrastText: "#fff",
          },
          success: {
            main: "#2e7d32",
            dark: "#1b5e20",
            light: "#4caf50",
            contrastText: "#fff",
          },
          action: {
            active: "rgba(0, 0, 0, 0.54)",
            hover: "rgba(0, 0, 0, 0.04)",
            disabled: "rgba(0, 0, 0, 0.26)",
            selected: "rgba(0, 0, 0, 0.08)",
            focus: "rgba(0, 0, 0, 0.12)",
            disabledBackground: "rgba(0, 0, 0, 0.12)",
          },
          grey: {
            "100": "#F5F5F5",
            "200": "#eee",
            "300": "#E0E0E0",
            "400": "#BDBDBD",
            "500": "#9E9E9E",
            "700": "#616161",
          },
          divider: "rgba(0, 0, 0, 0.12)",
          background: {
            default: "#FAFAFA",
            paper: "#fff",
          },
        }
      : {
          primary: {
            main: "#fff",
            dark: "#fff",
            light: "#dedede",
            contrastText: "#000",
          },
          secondary: {
            main: "#ff4843",
            dark: "#c4001a",
            light: "#ff7e6f",
            contrastText: "#000",
          },
          error: {
            main: "#d32f2f",
            dark: "#c62828",
            light: "#ef5350",
            contrastText: "#fff",
          },
          warning: {
            main: "#ed6c02",
            dark: "#e65100",
            light: "#ff9800",
            contrastText: "rgba(0, 0, 0, 0.87)",
          },
          info: {
            main: "#0288d1",
            dark: "#01579b",
            light: "#03a9f4",
            contrastText: "#fff",
          },
          success: {
            main: "#2e7d32",
            dark: "#1b5e20",
            light: "#4caf50",
            contrastText: "#fff",
          },
          grey: {
            "100": "#616161",
            "200": "#9E9E9E",
            "300": "#BDBDBD",
            "400": "#E0E0E0",
            "500": "#eee",
            "700": "#F5F5F5",
          },

          divider:
            mode !== "dark"
              ? "rgba(0, 0, 0, 0.12)"
              : "rgba(255, 255, 255, 0.12)",

          background: {
            default: "#121212",
          },
        }),
  },
  extraPalette: {
    blue: blue,
    green: green,
    red: red,
    yellow: yellow,
  },
  typography: {
    h1: {
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      fontSize: "96px",
      fontWeight: 300,
      lineHeight: 1.17,
      letterSpacing: "-1.5px",
    },
    h2: {
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      fontSize: "60px",
      fontWeight: 300,
      lineHeight: 1.2,
      letterSpacing: "-0.5px",
    },
    h3: {
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      fontSize: "48px",
      lineHeight: 1.17,
    },
    h4: {
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      fontSize: "34px",
      lineHeight: 1.24,
      letterSpacing: "0.25px",
    },
    h5: {
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      fontSize: "24px",
      lineHeight: 1.33,
    },
    h6: {
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      fontSize: "20px",
      fontWeight: 500,
      lineHeight: 1.6,
      letterSpacing: "0.15px",
    },
    subtitle1: {
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      fontSize: "16px",
      lineHeight: 1.75,
      letterSpacing: "0.15px",
    },
    body1: {
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      fontSize: "16px",
      lineHeight: 1.5,
      letterSpacing: "0.15px",
    },
    subtitle2: {
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: 1.57,
      letterSpacing: "0.1px",
    },
    button: {
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: 1.71,
      letterSpacing: "0.3px",
    },
    body2: {
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      fontSize: "14px",
      lineHeight: 1.43,
      letterSpacing: "0.15px",
    },
    overline: {
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      fontSize: "12px",
      lineHeight: 2.67,
      letterSpacing: "1px",
    },
    caption: {
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      fontSize: "12px",
      lineHeight: 1.67,
      letterSpacing: "0.4px",
    },
    fontFamily: [
      "Roboto",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    allVariants: {
      color: mode === "light" ? "#000" : "#fff", // switch color based on mode
    },
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        icon: {
          color: "inherit",
        },
      },
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          color: "inherit",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          "& .MuiSvgIcon-root": {
            color: "inherit",
          },
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          "& .MuiSvgIcon-root": {
            color: mode === "light" ? "#fff" : "#000",
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          marginRight: 4,
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          background: "rgba(0,0,0,0.5)",
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        "& .MuiSvgIcon-root": {
          color: mode === "light" ? "#fff" : "#000",
        },
        root: {
          "&.Mui-disabled .MuiSvgIcon-root": {
            color:
              mode === "light"
                ? "rgba(0, 0, 0, 0.26)"
                : "rgba(255, 255, 255, 0.26)",
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        "& .MuiSvgIcon-root": {
          color: mode === "light" ? "#fff" : "#000",
        },
        root: {
          "&.Mui-disabled .MuiSvgIcon-root": {
            color:
              mode === "light"
                ? "rgba(0, 0, 0, 0.26)"
                : "rgba(255, 255, 255, 0.26)",
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          padding: "4px 8px",
          borderRadius: "4px",
          boxShadow: "0 3px 5px -1px rgba(0, 0, 0, 0.2)",
          backgroundColor: mode === "light" ? "#FAFAFA" : "#212121",
          fontFamily: "Roboto",
          fontSize: "10px",
          fontWeight: 500,
          fontStretch: "normal",
          fontStyle: "normal",
          lineHeight: 1.4,
          letterSpacing: "normal",
          color: "var(--text-a-light-a-high-emphasis-text-primary)",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          shrink: {
            transform: "translate(0, -0.5px) scale(0.2)",
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&.MuiTableRow-hover:hover": {
            cursor: "pointer",
            backgroundColor: mode === "light" ? grey[400] : grey[800],
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${
            mode === "light" ? "rgba(0,0,0,0.12)" : "rgba(255,255,255,0.12)"
          }`,
        },
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          background: "#323232",
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          background: "rgba(0, 0, 0, 0.09)",
        },
      },
    },

    MuiDivider: {
      styleOverrides: {
        root: {
          marginTop: "32px",
          marginBottom: "32px",
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: "0",
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: mode === "light" ? "#000" : "#fff", // switch color based on mode
        },
      },
    },
  },
});
