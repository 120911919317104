import {
  ServiceKeysMap,
  ServiceKeysMapLowerCase,
  ServiceLabelsMap,
  ServiceTypeLowerCase,
} from "../types/services";

export const serviceLabels: ServiceTypeLowerCase[] = ["announcements"];

export const serviceKeysMap: ServiceKeysMap = {
  ANNOUNCEMENTS: "ANNOUNCEMENTS",
  SURVEYS: "SURVEYS",
};

export const serviceKeysMapLowerCase: ServiceKeysMapLowerCase = {
  ANNOUNCEMENTS: "announcements",
  SURVEYS: "surveys",
};

export const serviceLabelsMap: ServiceLabelsMap = {
  ANNOUNCEMENTS: "announcements",
  SURVEYS: "surveys",
};
