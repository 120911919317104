import { Autocomplete, Popper } from "@mui/material";
import { findIndex } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RATextField } from "../../../components/Inputs/RATextField";
import { SYSTEMEMPLOYEE } from "../../../constants/authentication";
import { allCustomersId } from "../../../constants/constants";
import { useIsPermitted } from "../../../hooks/authorization";
import { IdNamePair } from "../../../types/interfaces";
import { ID } from "../../../types/types";

interface Option {
  id: ID;
  name: string;
  firstLetter: string;
}
interface CustomerSelectorProps {
  customerId: string;
  customers: any[];
  disableAllCustomersOption?: boolean;
  disabled?: boolean;
  error?: boolean;
  onCustomerClick: (event: any) => void;
  required?: boolean;
  onBlur?: (event: any) => void;
}

function CustomerSelector(props: CustomerSelectorProps) {
  const [t] = useTranslation();
  const {
    customerId,
    disableAllCustomersOption,
    disabled,
    error,
    onBlur,
    onCustomerClick,
    required,
  } = props;
  const { isPermitted: systemPermission } = useIsPermitted(SYSTEMEMPLOYEE);
  const [customers, setCustomers] = useState<IdNamePair[]>();
  const [customerOptions, setCustomerOptions] = useState<Option[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<Option | null>(null);

  useEffect(() => {
    if (!!customerId && !!customerOptions) {
      const option = findIndex(customerOptions, (option: Option) => {
        return option.id === customerId;
      });
      setSelectedCustomer(customerOptions[option]);
    } else {
      setSelectedCustomer(null);
    }
  }, [customerId, customerOptions]);

  useEffect(() => {
    if (!!customers) {
      let options = customers.map((customer) => {
        const firstLetter = customer.name[0].toUpperCase();
        return {
          firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
          ...customer,
        };
      });
      options = options.sort(
        (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
      );
      if (systemPermission && !disableAllCustomersOption) {
        const allCustomersOption = {
          firstLetter: "A",
          id: allCustomersId,
          name: t("customers.all_customers"),
        };
        options.unshift(allCustomersOption);
      }
      setCustomerOptions(options);
    }
  }, [customers]);

  useEffect(() => {
    setCustomers(
      props.customers.sort((a, b) => {
        return Intl.Collator().compare(a.name, b.name);
      })
    );
  }, [props.customers]);

  return (
    <Autocomplete
      PopperComponent={(props: any) => (
        <Popper {...props} placement="top"></Popper>
      )}
      autoHighlight
      disabled={!!disabled}
      fullWidth
      onBlur={onBlur}
      noOptionsText={t("customers.no_options")}
      getOptionLabel={(option) => option.name}
      groupBy={(option) => option.firstLetter}
      id="grouped-customers"
      onChange={(_, selectedOption) =>
        onCustomerClick(selectedOption?.id || "")
      }
      options={customerOptions}
      renderInput={(params) => (
        <RATextField
          {...params}
          error={error}
          required={required}
          label={t("customers.customer")}
        />
      )}
      value={selectedCustomer || null}
    />
  );
}

export default CustomerSelector;
