import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Grid, Typography } from "@mui/material";
import MuiBreadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { has } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router";
import { RoutesTranslations, routes } from "../../../Routes";
import { stateCategoryMap } from "../../../constants/state";
import { useGlobalState } from "../../../state";

const exceptionList: string[] = [routes.theme];
const hideList: string[] = [
  "configuration",
  "content",
  "customer_settings",
  "operation",
  "users",
];

export default function Breadcrumbs() {
  const [t] = useTranslation();
  const [chosenUnitName] = useGlobalState(stateCategoryMap.CHOSEN_UNIT_NAME);
  const navigate = useNavigate();
  const location = useLocation();
  const [breadcrumbList, setBreadcrumbList] = useState<JSX.Element[]>([]);

  useEffect(() => {
    if (location.pathname) {
      if (exceptionList.includes(location.pathname)) {
        setBreadcrumbList([<div />]);
      } else {
        let fullPath = "";
        const paths = splitPaths(location.pathname);
        const pathArray: string[] = [];
        let newBreadCrumbList: JSX.Element[] = [];
        for (let i = 0; i < paths.length; i++) {
          const item = paths[i];
          fullPath += `/${item}`;
          pathArray.push(fullPath);
          if (hideList.includes(item)) {
            continue;
          }
          if (i + 1 === paths.length) {
            newBreadCrumbList.push(
              <Typography variant="body1" key={i} color="text.primary">
                {translateTitle(item)}
              </Typography>
            );
          } else {
            newBreadCrumbList.push(
              <Link
                color="inherit"
                key={i}
                onClick={() => navigate(pathArray[i])}
                style={{ cursor: "pointer" }}
                underline="hover"
              >
                <Typography variant="body1" key={i} color="text.primary">
                  {translateTitle(item)}
                </Typography>
              </Link>
            );
          }
        }
        setBreadcrumbList(newBreadCrumbList);
      }
    }
  }, [location, chosenUnitName, t]);

  const splitPaths = (path: string) => {
    if (path === "/general/operation/overview") {
      return ["general", "operation", "overview"];
    }
    return path
      .split("/")
      .filter(Boolean)
      .filter((name) => !name.includes("overview"));
  };
  const translations: { [key: string]: any } = RoutesTranslations();

  function translateTitle(item: string): string {
    if (has(translations, item)) {
      return translations[item];
    } else if (!!chosenUnitName) {
      return chosenUnitName;
    } else {
      return t("missing_translation");
    }
  }

  return (
    <Grid padding={5}>
      <MuiBreadcrumbs
        separator={<ChevronRightIcon fontSize="small" />}
        aria-label="breadcrumbs"
      >
        {breadcrumbList}
      </MuiBreadcrumbs>
    </Grid>
  );
}
