import { TFunction } from "i18next";
import { forEachRight, includes, isEmpty, sortBy } from "lodash";
import { languageIsos } from "../constants/Languages";
import { signalTypeMap } from "../constants/access";
import { DateInterval } from "../constants/constants";
import { URL_SUPPORT_MAP_BY_LANG } from "../constants/urls";
import i18n from "../i18n";
import { SignalType } from "../types/access";
import { Branch } from "../types/interfaces";
import { ServiceTypeLowerCase } from "../types/services";
import { ID } from "../types/types";
import { UnitType } from "../types/unitTypes";
import { isJSON, isNumber } from "./validations";
import { differenceInMonths, getUnixTime, subDays } from "date-fns";

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function toRelativePath(rootPath: string, absolutePath: string) {
  return absolutePath.replace(rootPath, "");
}

export function camelToSnakeCase(str: string) {
  return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
}

export function removeWhitespace(value: string) {
  return value.replace(/\s+/g, "");
}

export function snakeCaseToCamelCase(str: string): string {
  return str
    .split("_")
    .reduce(
      (res, word, i) =>
        i === 0
          ? word.toLowerCase()
          : `${res}${word.charAt(0).toUpperCase()}${word
              .substring(1)
              .toLowerCase()}`,
      ""
    );
}

export function nextAvailableNumberForKey(
  arr: any[],
  key: string,
  startAt?: number
) {
  var used = arr.reduce(function (o, v) {
    o[v[key]] = true;
    return o;
  }, {});
  for (var i = startAt || 0; used[i]; i++);
  return i;
}

export function convertNumberOfMinutesToHHMM(num: number) {
  if (num >= 0) {
    const hours = Math.floor(num / 60);
    const hourString =
      hours.toString().length === 1 ? "0" + hours.toString() : hours.toString();
    const minutes = num % 60;
    const minuteString =
      minutes.toString().length === 1
        ? "0" + minutes.toString()
        : minutes.toString();
    return hourString + ":" + minuteString;
  } else {
    const absNum = Math.abs(num);
    const hours = Math.floor(absNum / 60);
    const hourString =
      hours.toString().length === 1 ? "0" + hours.toString() : hours.toString();
    const minutes = absNum % 60;
    const minuteString =
      minutes.toString().length === 1
        ? "0" + minutes.toString()
        : minutes.toString();
    return "-" + hourString + ":" + minuteString;
  }
}

export function formatBytes(bytes: number, decimals: number = 2) {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export function mapDirectionForUnit(
  unitType: UnitType | undefined
): SignalType {
  if (!unitType) {
    return signalTypeMap.OUTPUT;
  }
  const inputs = ["doorsensor", "override"];
  if (inputs.includes(unitType.toLowerCase())) {
    return signalTypeMap.INPUT;
  } else {
    return signalTypeMap.OUTPUT;
  }
}

export function numCharsValid(value: any) {
  if (!value) return true;

  if (isNumber(value)) {
    return true;
  } else {
    return false;
  }
}

export function isColor(strColor: string) {
  const s = new Option().style;
  s.color = strColor;
  return s.color !== "";
}

export function getFieldsFromArray(array: any[], field: string) {
  var output = [];
  for (var i = 0; i < array.length; ++i) output.push(array[i][field]);
  return output;
}

export function getAllIndexes(
  array: any[],
  key: string,
  value: string | number
) {
  var indexes = [],
    i;
  for (i = 0; i < array.length; i++)
    if (array[i][key] === value) indexes.push(i);
  return indexes;
}

export function arrayHasDuplicates(array: any[], key: string) {
  const valueArr = array.map(function (item) {
    return item[key];
  });
  const isDuplicate = valueArr.some(function (item, idx) {
    return valueArr.indexOf(item) !== idx;
  });
  return isDuplicate;
}

export function removeFromArrayById(array: any[], id: ID) {
  const i = array.findIndex((x) => x.deviceId === id);
  if (i > -1) {
    array.splice(i, 1);
  }
  return array;
}

export function removeAllFromArrayById(array: any[], id: ID) {
  for (var i = 0; i < array.length; i++) {
    if (array[i].deviceId === id) {
      array.splice(i, 1);
      i--;
    }
  }
  return array;
}

export function removeAllIDsFromArray(array: ID[], id: ID) {
  var filteredArray = array.filter(function (value) {
    return value !== id;
  });
  return filteredArray;
}

export function removeAllElementsFromArrayByIDs(
  array: any[],
  indexes: number[]
) {
  const sortedIndexes = sortBy(indexes);
  forEachRight(sortedIndexes, (index) => {
    array.splice(index, 1);
  });
  return array;
}

export function handleJSONParsing(parseThis: any) {
  if (isJSON(parseThis)) {
    return JSON.parse(parseThis);
  }
  return [];
}

export function sumOfArrayProperty(array: any[], prop: string) {
  if (isEmpty(array)) {
    return 0;
  }
  let sum: number = array
    .map((a) => a[prop])
    .reduce(function (a, b) {
      return a + b;
    });
  return sum;
}

export function decimalToHexString(decimal: number | string) {
  let number = decimal;
  if (!number) {
    return "00";
  }
  if (typeof number === "string") {
    number = parseInt(number);
  }
  if (number < 0) {
    number = 0xffffffff + number + 1;
  }
  let result = number.toString(16).toUpperCase();
  if (number < 16) {
    result = "0" + result;
  }
  return result;
}

export function stringToArray(
  stringValue: string,
  separator?: string
): string[] {
  if (!stringValue) {
    return [];
  }
  //Remove white spaces after or before a comma
  stringValue = stringValue.replace(/\s*,\s*/g, ",");
  if (!!separator) {
    return stringValue.split(separator);
  } else {
    return stringValue.split(",");
  }
}

export function hexValueDiffer(
  initValue: string | number,
  newValue: string | number
) {
  return parseHex(initValue) !== parseHex(newValue);
}

export function parseHex(value: string | number) {
  if (typeof value === "string") {
    value = parseInt(value, 16);
  }
  return value.toString();
}

export function showInputLength(maxLength: number, value?: string): string {
  if (!value) {
    return `0 / ${maxLength}`;
  }
  return `${value.length} / ${maxLength}`;
}

export function parseServiceList(
  serviceList: ServiceTypeLowerCase[],
  servicesString?: string
): ServiceTypeLowerCase[] {
  const result: ServiceTypeLowerCase[] = [];
  if (!servicesString) {
    return result;
  }
  const services = JSON.parse(servicesString);
  services.forEach((service: ServiceTypeLowerCase) => {
    if (includes(serviceList, service)) {
      result.push(service);
    }
  });
  return result;
}

export function arrayToString(
  arrayValue?: string[],
  separator?: string
): string {
  if (!arrayValue || !Array.isArray(arrayValue)) {
    return "";
  } else {
    const isStringArray =
      arrayValue.length > 0 &&
      arrayValue.every((value) => {
        return typeof value === "string";
      });
    if (!isStringArray) {
      return "";
    }
  }
  if (!!separator) {
    return arrayValue.join(separator);
  } else {
    return arrayValue.join();
  }
}

export function verifyBoolean(value: any): boolean {
  return value === true || value === "true";
}

export function parseConfigValue(value: any): string {
  if (typeof value === "string") {
    return value;
  } else if (typeof value === "number") {
    return value.toString();
  } else if (typeof value === "boolean") {
    return value.toString();
  } else if (Array.isArray(value)) {
    return JSON.stringify(value);
  } else {
    return value.toString();
  }
}

export function parseLanguageIso(language: string): string {
  const firstTwoChars = language.slice(0, 2);
  const languageIsoKeys = Object.keys(languageIsos);
  if (!includes(languageIsoKeys, firstTwoChars)) {
    return languageIsos.da;
  }
  return firstTwoChars;
}

export function navigateToUrlInNewTab(url: string) {
  window.open(url, "_blank");
}

export function isValidJSONOrEmpty(s: string) {
  if (s === "") {
    return true;
  } else {
    try {
      JSON.parse(s);
      return true;
    } catch (e) {
      return false;
    }
  }
}

export function reverseString(str: string) {
  const splitString = str.split("");
  const reverseArray = splitString.reverse();
  const joinArray = reverseArray.join("");

  return joinArray;
}

export function handleLimitCheckBasic(
  dateInterval: DateInterval,
  maxDateIntervalInMonths: number
): boolean {
  const endDateMinusOne = subDays(new Date(dateInterval.end), 1);
  const startDate = new Date(dateInterval.start);
  const durationInMonths = differenceInMonths(endDateMinusOne, startDate);
  const intervalLimitExceeded = maxDateIntervalInMonths <= durationInMonths;
  return intervalLimitExceeded;
}

export function renderDateInterval(
  start: string,
  end: string,
  t: TFunction
): string {
  const from = renderDate(start, t);
  const to = renderDate(end, t);

  return `${from} - ${to}`;
}

export function renderDate(date: string, t: TFunction): string {
  return new Date(date).toLocaleDateString(i18n.language, {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
}

export function convertNamesForChosenBranches(
  branchList: Branch[],
  chosenBranches: ID[]
): string[] {
  let branchesSubset: string[] = [];
  branchList.forEach((branch) => {
    if (includes(chosenBranches, branch.id)) {
      branchesSubset.push(branch.name);
    }
  });
  return branchesSubset;
}

export function dateExceeded(date: Date | null): boolean {
  return !!date && date < new Date();
}

// Sleep function implemented for loading control. Should be avoided if possible.
export function sleep(milliseconds: number) {
  return new Promise<void>((resolve) => {
    setTimeout(resolve, milliseconds);
  });
}

export function isLetter(str: string) {
  return str.length === 1 && str.match(/[a-z]/i);
}

export function getSupportUrlForLanguage(lang: string) {
  switch (lang) {
    case languageIsos.da:
      return URL_SUPPORT_MAP_BY_LANG.da.home;
    case languageIsos.de:
      return URL_SUPPORT_MAP_BY_LANG.de.home;
    case languageIsos.no:
      return URL_SUPPORT_MAP_BY_LANG.no.home;
    case languageIsos.nb:
      return URL_SUPPORT_MAP_BY_LANG.no.home;
    case languageIsos.en:
      return URL_SUPPORT_MAP_BY_LANG.en.home;
    default:
      return URL_SUPPORT_MAP_BY_LANG.da.home;
  }
}

export function isEnvironment(env: string): boolean {
  return import.meta.env.VITE_ENV === env;
}

export function isValidTimeFormat(time: string) {
  const valid = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(time);
  return valid;
}

export function pathIncludesSubject(path: string, subject: string): boolean {
  const pathArray = path.split("/");
  for (let i = 0; i < pathArray.length; i++) {
    if (pathArray[i] === subject) {
      return true;
    }
  }
  return false;
}
