export function consoleError(error: Error | string, overrideName?: string) {
  let name: string;
  let message: string;

  if (error instanceof Error) {
    name = overrideName ? overrideName : error.name;
    message = error.message;
  } else {
    name = overrideName ? overrideName : "Redia Admin Error";
    message = error;
  }

  console.groupCollapsed(name);
  console.error(message);
  console.groupEnd();
}
