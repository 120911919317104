import { ListItemButton, ListItemText } from "@mui/material";
import List from "@mui/material/List";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router";
import { useAuth0 } from "../../../auth0/ReactAuth0Wrapper";
import { allCustomersId } from "../../../constants/constants";
import { SubMenu, operationMenu } from "../../../constants/menus";
import { stateCategoryMap } from "../../../constants/state";
import { useGlobalState } from "../../../state";
import { SubjectLabel } from "../../../types/subjects";
import { isPermitted } from "../../../utils/authorization";
import { navigationScopeMap } from "../../../constants/subjects";

interface OperationMenuProps {
  subject: SubjectLabel;
}

function OperationMenu(props: OperationMenuProps) {
  const [t] = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAuth0();
  const [globalCustomer] = useGlobalState(stateCategoryMap.CUSTOMER);
  const isGlobalConfiguration: boolean = globalCustomer.id === allCustomersId;
  const [roleLevels] = useGlobalState(stateCategoryMap.ROLE_LEVELS);
  const { subject } = props;
  const [selectedKey, setSelectedKey] = useState("");
  const [renderMenus, setRenderMenus] = useState<SubMenu[]>([]);

  useEffect(() => {
    if (!!operationMenu[subject]) {
      const availableSubMenus: SubMenu[] = [];
      operationMenu[subject].forEach((subMenu) => {
        if (
          (isGlobalConfiguration && subMenu.excludeFromMetaLayer) ||
          (!isGlobalConfiguration && subMenu.excludeFromCustomerLayer)
        ) {
          return;
        }
        if (isPermitted(user, roleLevels, subMenu.visibleFor)) {
          availableSubMenus.push(subMenu);
        }
      });
      setRenderMenus(availableSubMenus);
    }
  }, [subject, roleLevels]);

  useEffect(() => {
    renderMenus.forEach((s: SubMenu) => {
      if (location.pathname.includes(s.scope)) {
        setSelectedKey(s.scope);
        return;
      }
    });
  }, [renderMenus]);

  const handleListItemClick = (subMenu: SubMenu) => {
    setSelectedKey(subMenu.scope);
    handleNavigateTo(subMenu.route);
  };
  function handleNavigateTo(path: string) {
    if (location.pathname !== path) {
      navigate(path);
    }
  }

  return (
    <List component="div" disablePadding>
      {renderMenus.map((subMenu: SubMenu, index: number) => {
        return (
          <ListItemButton
            key={index}
            sx={{ pl: 4 }}
            selected={selectedKey === subMenu.scope}
            onClick={() => handleListItemClick(subMenu)}
          >
            <ListItemText
              primary={t(`operation.${subject}.${subMenu.scope}`)}
            />
          </ListItemButton>
        );
      })}
    </List>
  );
}

export default OperationMenu;
