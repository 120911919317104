import React from "react";
import LeftMenu from "../LeftMenu/LeftMenu";

interface NavigationWrapperProps {
  hideLeftMenu: boolean;
  children: React.ReactNode;
}

function NavigationWrapper(props: NavigationWrapperProps) {
  const { hideLeftMenu, children } = props;

  return <>
        {!hideLeftMenu && <LeftMenu />}
          {children}
        </>;
}

export default NavigationWrapper;
