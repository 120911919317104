import { useQuery } from "@apollo/client";
import { includes, omit } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { allCustomersId } from "../constants/constants";
import { configKeys } from "../constants/keys";
import { serviceLabels } from "../constants/services";
import { stateCategoryMap } from "../constants/state";
import { customerQuery } from "../GraphQL/customer/queries";
import { useGlobalState } from "../state";
import { ServiceTypeLowerCase } from "../types/services";

interface LanguageMapObject {
  [key: string]: string;
}

export function useLanguageMapObject(
  languagesToOmit?: string[]
): LanguageMapObject {
  const [t] = useTranslation();
  const languageMap: LanguageMapObject = {
    dan: t("languages.dan"),
    deu: t("languages.deu"),
    eng: t("languages.eng"),
    fao: t("languages.fao"),
    nno: t("languages.nno"),
    nob: t("languages.nob"),
    swe: t("languages.swe"),
  };
  if (!!languagesToOmit) {
    return omit(languageMap, languagesToOmit);
  }
  return languageMap;
}

export function useLMS(): string {
  const [globalCustomerState] = useGlobalState("customer");
  const { data } = useQuery(customerQuery, {
    variables: { customerId: globalCustomerState.id },
  });
  const [lms, setLms] = useState("");

  useEffect(() => {
    if (data?.customer) {
      const configMap = data?.customer?.configMap;
      if (configMap) {
        const libraryService =
          configMap[configKeys.general.librarySystem.libraryService];
        setLms(libraryService);
      }
    }
  }, [data]);

  return lms;
}

export function useActivatedServices(): {
  activatedServices: ServiceTypeLowerCase[];
  completed: boolean;
} {
  const [globalCustomer] = useGlobalState(stateCategoryMap.CUSTOMER);
  let services: ServiceTypeLowerCase[] = [];
  let completed: boolean = false;
  if (!!globalCustomer && globalCustomer === allCustomersId) {
    return {
      activatedServices: serviceLabels,
      completed: true,
    };
  }
  if (!globalCustomer || !globalCustomer.configMap) {
    return {
      activatedServices: services,
      completed: completed,
    };
  }
  completed = true;
  if (!!globalCustomer.configMap[configKeys.customer.services]) {
    services = JSON.parse(
      globalCustomer.configMap[configKeys.customer.services]
    );
  }
  return {
    activatedServices: services,
    completed: completed,
  };
}
