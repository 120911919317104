import { gql } from "@apollo/client";

export const customerMutation = gql`
  mutation UpdateCustomer($id: ID!, $changes: CustomerUpdateInput!) {
    updateCustomer(id: $id, params: $changes) {
      id
      name
      country
    }
  }
`;

export const customerProductMutation = gql`
  mutation UpdateCustomerProduct($params: updateCustomerProductInput!) {
    toggleCustomerProduct(params: $params)
  }
`;

export const createCustomerMutation = gql`
  mutation CreateCustomer($params: CustomerCreateInput!) {
    createCustomer(params: $params) {
      id
    }
  }
`;

export const uploadFileMutation = gql`
  mutation($file: Upload!) {
    uploadFile(file: $file)
  }
`;

export const deleteCustomerMutation = gql`
  mutation DeleteCustomer($id: ID!) {
    deleteCustomer(id: $id) {
      id
    }
  }
`;

export const createCustomerGroupMutation = gql`
  mutation CreateCustomerGroup($params: createCustomerGroupInput!) {
    createCustomerGroup(params: $params) {
      id
    }
  }
`;

export const deleteCustomerGroupMutation = gql`
  mutation DeleteCustomerGroup($id: ID!) {
    deleteCustomerGroup(id: $id) {
      id
    }
  }
`;