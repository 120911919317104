import { routes } from "../Routes";
import { RoleLevel } from "../types/authentication";
import { SubjectLabel } from "../types/subjects";
import {
  ADMIN,
  EDITOR,
  EMPLOYEE,
  INSPECTOR,
  NONE,
  PARTNERADMIN,
  SYSTEMADMIN,
  SYSTEMEMPLOYEE,
} from "./authentication";
import { navigationScopeMap } from "./subjects";

export interface SubMenu {
  scope: string;
  visibleFor: RoleLevel;
  excludeFromCustomerLayer?: boolean;
  excludeFromMetaLayer?: boolean;
  route: string;
}
export type NavigationMenu = {
  [key in SubjectLabel]: SubMenu[];
};
export const configurationMenu: NavigationMenu = {
  general: [
    {
      excludeFromMetaLayer: true,
      scope: navigationScopeMap.LIBRARY_SYSTEM,
      visibleFor: EMPLOYEE,
      route: routes.general.configuration.library_system,
    },
    {
      excludeFromMetaLayer: true,
      scope: navigationScopeMap.BRANCH_CONFIGURATION,
      visibleFor: EMPLOYEE,
      route: routes.general.configuration.branch_configuration,
    },
    {
      excludeFromMetaLayer: true,
      scope: navigationScopeMap.IDENTITY,
      visibleFor: EMPLOYEE,
      route: routes.general.configuration.identity,
    },
    {
      excludeFromMetaLayer: true,
      scope: navigationScopeMap.CUSTOMER,
      visibleFor: PARTNERADMIN,
      route: routes.general.configuration.customer_data,
    },
    {
      excludeFromMetaLayer: true,
      scope: navigationScopeMap.EXTERNAL_SYSTEMS,
      visibleFor: EMPLOYEE,
      route: routes.general.configuration.external_systems,
    },
    {
      excludeFromMetaLayer: true,
      scope: navigationScopeMap.OPENING_HOURS,
      visibleFor: NONE,
      route: routes.general.configuration.opening_hours,
    },
    {
      excludeFromCustomerLayer: true,
      scope: navigationScopeMap.PLATFORM,
      visibleFor: SYSTEMEMPLOYEE,
      route: routes.general.configuration.platform,
    },
  ],
  access: [
    {
      scope: navigationScopeMap.BLOCK_LIST,
      visibleFor: ADMIN,
      route: routes.access.configuration.block_list,
    },
    {
      scope: navigationScopeMap.BRANCH_CONFIGURATION,
      visibleFor: EMPLOYEE,
      route: routes.access.configuration.branch_configuration,
    },
    {
      scope: navigationScopeMap.UNITS,
      visibleFor: EMPLOYEE,
      route: routes.access.configuration.units,
    },
    {
      scope: navigationScopeMap.COMMON_CONFIG,
      visibleFor: EMPLOYEE,
      route: routes.access.configuration.common_config,
    },
    {
      scope: navigationScopeMap.AUDIO_CONTROL,
      visibleFor: EMPLOYEE,
      route: routes.access.configuration.audio_control,
    },
    {
      scope: navigationScopeMap.EVENT_STATES,
      visibleFor: EMPLOYEE,
      route: routes.access.configuration.event_states,
    },
    {
      scope: navigationScopeMap.SCHEDULING,
      visibleFor: EMPLOYEE,
      route: routes.access.configuration.scheduling,
    },
  ],
  assist: [
    {
      scope: navigationScopeMap.UNITS,
      visibleFor: PARTNERADMIN,
      route: routes.assist.configuration.units,
    },
    {
      scope: navigationScopeMap.COMMON_CONFIG,
      visibleFor: EMPLOYEE,
      route: routes.assist.configuration.common_config,
    },
  ],
  app: [
    {
      scope: navigationScopeMap.IP_ADDRESSES,
      visibleFor: SYSTEMEMPLOYEE,
      route: routes.app.configuration.ip_addresses,
    },
    {
      scope: navigationScopeMap.CHECKINS_AND_CHECKOUTS,
      visibleFor: SYSTEMEMPLOYEE,
      route: routes.app.configuration.checkins_and_checkouts,
    },
    {
      scope: navigationScopeMap.NEWS_AND_EVENTS,
      visibleFor: SYSTEMEMPLOYEE,
      route: routes.app.configuration.news_and_events,
    },
  ],
  library_app: [
    {
      scope: navigationScopeMap.COMMON_CONFIG,
      visibleFor: EMPLOYEE,
      route: routes.library_app.configuration.common_config,
    },
  ],
  butler: [
    {
      scope: navigationScopeMap.UNITS,
      visibleFor: EMPLOYEE,
      route: routes.butler.configuration.units,
    },
    {
      scope: navigationScopeMap.BRANCH_CONFIGURATION,
      visibleFor: EMPLOYEE,
      route: routes.butler.configuration.branch_configuration,
    },
    {
      scope: navigationScopeMap.COMMON_CONFIG,
      visibleFor: EMPLOYEE,
      route: routes.butler.configuration.common_config,
    },
  ],
  gates: [
    {
      scope: navigationScopeMap.UNITS,
      visibleFor: EMPLOYEE,
      route: routes.gates.configuration.units,
    },
  ],
  inspire: [
    {
      scope: navigationScopeMap.UNITS,
      visibleFor: EMPLOYEE,
      route: routes.inspire.configuration.units,
    },
    {
      scope: navigationScopeMap.COMMON_CONFIG,
      visibleFor: EMPLOYEE,
      route: routes.inspire.configuration.common_config,
    },
  ],
  people_counter: [
    {
      scope: navigationScopeMap.UNITS,
      visibleFor: EMPLOYEE,
      route: routes.people_counter.configuration.units,
    },
  ],
};

export const customerSettingsMenu: NavigationMenu = {
  general: [
    {
      scope: navigationScopeMap.CUSTOMER_GROUPS,
      visibleFor: SYSTEMADMIN,
      route: routes.general.customer_settings.customer_groups,
    },
  ],
};

export const usersMenu: NavigationMenu = {
  general: [
    {
      scope: navigationScopeMap.USER_ADMINISTRATION,
      visibleFor: EMPLOYEE,
      route: routes.general.users.user_administration,
    },
  ],
};

export const contentMenu: NavigationMenu = {
  general: [
    {
      scope: navigationScopeMap.ANNOUNCEMENTS,
      visibleFor: EDITOR,
      route: routes.general.content.announcements,
    },
    {
      excludeFromMetaLayer: true,
      scope: navigationScopeMap.SURVEYS,
      visibleFor: EMPLOYEE,
      route: routes.general.content.surveys,
    },
  ],
};

export const translationsMenu: NavigationMenu = {
  general: [
    {
      scope: navigationScopeMap.ACCESS_TRANSLATIONS,
      visibleFor: SYSTEMEMPLOYEE,
      excludeFromCustomerLayer: true,
      route: routes.general.translation_management.access,
    },
    {
      scope: navigationScopeMap.BUTLER_TRANSLATIONS,
      visibleFor: SYSTEMEMPLOYEE,
      excludeFromCustomerLayer: true,
      route: routes.general.translation_management.butler,
    },
    {
      scope: navigationScopeMap.LIBRARY_APP_TRANSLATIONS,
      visibleFor: SYSTEMEMPLOYEE,
      excludeFromCustomerLayer: true,
      route: routes.general.translation_management.library_app,
    },
  ],
};

export const operationMenu: NavigationMenu = {
  access: [
    {
      scope: navigationScopeMap.LOG,
      visibleFor: INSPECTOR,
      route: routes.access.operation.log,
    },
    {
      scope: navigationScopeMap.UNIT_STATUS,
      visibleFor: EMPLOYEE,
      route: routes.access.operation.unit_status,
    },
  ],
  app: [
    {
      scope: navigationScopeMap.STATISTICS,
      visibleFor: EMPLOYEE,
      route: routes.app.operation.statistics,
    },
  ],
  butler: [
    {
      excludeFromMetaLayer: true,
      scope: navigationScopeMap.STATISTICS,
      visibleFor: EMPLOYEE,
      route: routes.butler.operation.statistics,
    },
    {
      excludeFromMetaLayer: true,
      scope: navigationScopeMap.UNIT_STATUS,
      visibleFor: EMPLOYEE,
      route: routes.butler.operation.unit_status,
    },
    {
      excludeFromCustomerLayer: true,
      scope: navigationScopeMap.META_UNIT_STATUS,
      visibleFor: SYSTEMEMPLOYEE,
      route: routes.butler.operation.meta_unit_status,
    },
  ],
  gates: [
    {
      scope: navigationScopeMap.LOG,
      visibleFor: EMPLOYEE,
      route: routes.gates.operation.log,
    },
  ],
  general: [
    {
      excludeFromCustomerLayer: true,
      scope: navigationScopeMap.OVERVIEW,
      visibleFor: SYSTEMEMPLOYEE,
      route: routes.general.operation.overview,
    },
  ],
  library_app: [
    {
      scope: navigationScopeMap.STATISTICS,
      visibleFor: EMPLOYEE,
      route: routes.library_app.operation.statistics,
    },
  ],
  people_counter: [
    {
      scope: navigationScopeMap.STATISTICS,
      visibleFor: EMPLOYEE,
      route: routes.people_counter.operation.statistics,
    },
  ],
};
