import React from "react";
import { useGlobalState } from "../../state";
import { RAGlobalErrorDialog } from "../../components/RAGlobalErrorDialog";
import { stateCategoryMap } from "../../constants/state";

interface ErrorWrapperProps {
  children: React.ReactNode;
}

function ErrorWrapper(props: ErrorWrapperProps) {
  const { children } = props;
  const [globalError, setGlobalError] = useGlobalState(stateCategoryMap.GLOBAL_ERROR);

  function handleCloseError() {
    setGlobalError({ isFeedback: false, message: null });
  }

  return (
    <>
      <RAGlobalErrorDialog
        open={!!globalError.message}
        error={globalError.message}
        onClose={() => handleCloseError()}
        isFeedback={globalError.isFeedback}
      />
      {children}
    </>
  );
}

export default ErrorWrapper;
