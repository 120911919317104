import { gql } from "@apollo/client";

export const getAuthZeroUser = gql`
  query ($userID: String!) {
    getAuthZeroUser(userID: $userID) {
      customerId
      email
      emailVerified
      name
      partnerId
      userId
      roles {
        description
        id
        name
      }
    }
  }
`;

export const getAuthZeroUsers = gql`
  query ($customerID: String, $partnerID: String, $page: Int) {
    getAuthZeroUsers(
      customerID: $customerID
      partnerID: $partnerID
      page: $page
    ) {
      customerId
      createdAt
      email
      emailVerified
      name
      partnerId
      updatedAt
      userId
      roles {
        description
        id
        name
      }
    }
  }
`;

export const getAuthZeroUsersForRoleId = gql`
  query ($roleId: ID!, $page: Int) {
    getAuthZeroUsersForRole(roleId: $roleId, page: $page) {
      name
      email
      userId
    }
  }
`;

export const getAuthZeroRoles = gql`
  {
    getAuthZeroRoles {
      id
      name
    }
  }
`;

export const getRoleInfo = gql`
  {
    getRoleInfo {
      roleLevels
      configPermissionMap
    }
  }
`;
