import { StateCategoryMap } from "../types/state";

export const stateCategories = [
  "authToken",
  "configPermissionMap",
  "countries",
  "customer",
  "customerUsers",
  "globalError",
  "globalLoading",
  "keyboardTypes",
  "languages",
  "roleLevels",
  "user",
];

export const stateCategoryMap: StateCategoryMap = {
  AUTH_TOKEN: "authToken",
  CHOSEN_UNIT_NAME: "chosenUnitName",
  CONFIG_PERMISSION_MAP: "configPermissionMap",
  COUNTRIES: "countries",
  CUSTOMER: "customer",
  CUSTOMER_USERS: "customerUsers",
  GLOBAL_ERROR: "globalError",
  GLOBAL_LOADING: "globalLoading",
  GLOBAL_SNACKBAR: "globalSnackbar",
  KEYBOARD_TYPES: "keyboardTypes",
  LANGUAGES: "languages",
  ROLE_LEVELS: "roleLevels",
  USER: "user",
};
