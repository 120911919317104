import { ArrowBack } from "@mui/icons-material";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  ClickAwayListener,
  Fab,
  Grid,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router";
import { routes } from "../Routes";
import { useAuth0 } from "../auth0/ReactAuth0Wrapper";
import { FRONTEND_URL } from "../config";
import { FormSectionStack } from "../layouts/Body";
import Breadcrumbs from "../routes/Theme/components/Breadcrumbs";
import PlatformLanguagePicker from "./PlatformLanguagePicker";

const FrontPopper = styled(Popper)`
  z-index: 1100;
`;
const Wrapper = styled("div")`
  height: 56px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 2;
`;

const ProfileButton = styled("div")`
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-shadow: none !important;
  cursor: pointer;
  margin: 0 5px 0 0;
`;
const ProfileTitle = styled(Typography)`
  padding: 0 10px;
`;

function Header() {
  const [t] = useTranslation();
  const { isAuthenticated, user, logout } = useAuth0();
  const location = useLocation();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  const isDarkMode = useTheme().palette.mode === "dark";

  function handleProfileClick() {
    navigate(routes.profile);
    handleCloseMenu();
  }
  function handleOpenMenu(event: React.MouseEvent<HTMLDivElement>) {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  }
  function handleCloseMenu() {
    setOpen(false);
  }

  return (
    <Wrapper>
      {!isAuthenticated && (
        <img
          src={isDarkMode ? "/redia-logo-white.svg" : "/redia-logo-black.svg"}
          alt="Logo"
          style={{
            width: "130px",
            margin: "24px 0 0 16px",
          }}
          decoding="auto"
        />
      )}
      {location.pathname === routes.theme && (
        <Fab
          style={{ marginLeft: "10px" }}
          size="small"
          color="primary"
          onClick={() => navigate(routes.home, { replace: true })}
        >
          <ArrowBack />
        </Fab>
      )}
      <div>{isAuthenticated ? <Breadcrumbs /> : <div />}</div>
      <div>
        <FormSectionStack direction="row">
          <Grid>
            <PlatformLanguagePicker />
          </Grid>
          <Grid>
            {isAuthenticated && (
              <>
                <ProfileButton
                  onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                    handleOpenMenu(event);
                  }}
                >
                  <ProfileTitle variant="body1">{user.name}</ProfileTitle>
                  <ExpandMore />
                </ProfileButton>
                <FrontPopper
                  open={open}
                  anchorEl={anchorEl}
                  placement="bottom-end"
                  transition
                  disablePortal
                >
                  {({ TransitionProps }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin: "right top",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleCloseMenu}>
                          <MenuList
                            autoFocusItem={open}
                            id="composition-menu"
                            aria-labelledby="composition-button"
                          >
                            <MenuItem onClick={handleProfileClick}>
                              {t("profile.to_user_info")}
                            </MenuItem>
                            <MenuItem
                              onClick={() => logout({ returnTo: FRONTEND_URL })}
                            >
                              {t("profile.logout")}
                            </MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </FrontPopper>
              </>
            )}
          </Grid>
        </FormSectionStack>
      </div>
    </Wrapper>
  );
}

export default Header;
