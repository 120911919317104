import React from "react";
import { RABackdrop } from "../../components/RABackdrop";
import { stateCategoryMap } from "../../constants/state";
import { useGlobalState } from "../../state";

interface LoadingWrapperProps {
  children: React.ReactNode;
}

function LoadingWrapper(props: LoadingWrapperProps) {
  const { children } = props;
  const [globalLoading] = useGlobalState(stateCategoryMap.GLOBAL_LOADING);

  return (
    <>
      <RABackdrop open={globalLoading} />
      {children}
    </>
  );
}

export default LoadingWrapper;
