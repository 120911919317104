import { de } from "date-fns/locale";
import { SupportUrlMap } from "../types/urls";

const URL_SUPPORT_DA = "https://support.redia.dk/";
const URL_SUPPORT_DE = "https://support.redia.dk/de";
const URL_SUPPORT_NO = "https://support.redia.dk/no-no";
const URL_SUPPORT_EN = "https://support.redia.dk/en";

const URL_SUPPORT_ACCESS_DA = "https://support.redia.dk/access";
const URL_SUPPORT_ACCESS_NO = "https://support.redia.dk/no-no/access";

const URL_SUPPORT_ASSIST_DA = "https://support.redia.dk/assist";
const URL_SUPPORT_ASSIST_NO = "https://support.redia.dk/no-no/assist";
const URL_SUPPORT_ASSIST_EN = "https://support.redia.dk/en/assist";

const URL_SUPPORT_BUTLER_DA = "https://support.redia.dk/butler";
const URL_SUPPORT_BUTLER_NO = "https://support.redia.dk/no-no/butler";

const URL_SUPPORT_INSPIRE_DA = "https://support.redia.dk/inspire";

const URL_SUPPORT_APP_DA = "https://support.redia.dk/biblioteket-appen";
const URL_SUPPORT_LIBRARY_APP_EN = "https://support.redia.dk/en/library-app";

export const URL_SUPPORT_MAP_BY_LANG: SupportUrlMap = {
  da: {
    access: URL_SUPPORT_ACCESS_DA,
    app: URL_SUPPORT_APP_DA,
    assist: URL_SUPPORT_ASSIST_DA,
    butler: URL_SUPPORT_BUTLER_DA,
    home: URL_SUPPORT_DA,
    inspire: URL_SUPPORT_INSPIRE_DA,
    libraryApp: URL_SUPPORT_LIBRARY_APP_EN,
  },
  de: {
    home: URL_SUPPORT_DE,
  },
  no: {
    access: URL_SUPPORT_ACCESS_NO,
    app: URL_SUPPORT_APP_DA,
    assist: URL_SUPPORT_ASSIST_NO,
    butler: URL_SUPPORT_BUTLER_NO,
    home: URL_SUPPORT_NO,
    inspire: URL_SUPPORT_INSPIRE_DA,
    libraryApp: URL_SUPPORT_LIBRARY_APP_EN,
  },
  en: {
    access: URL_SUPPORT_ACCESS_DA,
    app: URL_SUPPORT_APP_DA,
    assist: URL_SUPPORT_ASSIST_EN,
    butler: URL_SUPPORT_BUTLER_DA,
    home: URL_SUPPORT_EN,
    inspire: URL_SUPPORT_INSPIRE_DA,
    libraryApp: URL_SUPPORT_LIBRARY_APP_EN,
  },
};
