import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import RADialog from "./RADialog";

interface RAGlobalErrorDialogProps {
  error: any;
  open: boolean;
  onClose: () => void;
  isFeedback?: boolean;
}

export function RAGlobalErrorDialog(props: RAGlobalErrorDialogProps) {
  const [t] = useTranslation();
  const { error, open, onClose, isFeedback } = props;

  return (
    <RADialog open={open} title={t("error")}>
      <DialogContent>
        {isFeedback ? (
          <Typography variant="body1">{String(error)}</Typography>
        ) : (
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Typography variant="body1">{t("error_specific")}</Typography>
              <code>{String(error)}</code>
            </Grid>
            <Grid item>
              <Typography variant="body1">{t("contact_redia")}</Typography>
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          {t("ok")}
        </Button>
      </DialogActions>
    </RADialog>
  );
}
