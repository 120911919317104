export const grey = {
  50: "#ffffff",
  100: "#fafafa",
  200: "#f5f5f5",
  300: "#f0f0f0",
  400: "#dedede",
  500: "#c2c2c2",
  600: "#979797",
  700: "#818181",
  800: "#606060",
  900: "#3c3c3c",
  A100: "#f2f2f2",
  A200: "#ededed",
  A400: "#d6d6d6",
  A700: "#797979",
};

export const red = {
  50: "#FFDAD9",
  100: "#FFBDBB",
  200: "#FFA09D",
  300: "#FF837F",
  400: "#FF6561",
  500: "#FF4843",
  600: "#DF3F3B",
  700: "#BF3632",
  800: "#9F2D2A",
  900: "#802422",
  A100: "#ffffff",
  A200: "#fffafa",
  A400: "#ffc7cb",
  A700: "#ffadb4",
  contrastDefaultColor: "light",
};

export const green = {
  50: "#D6F0E7",
  100: "#B6E4D3",
  200: "#95D8C0",
  300: "#75CBAC",
  400: "#55BF99",
  500: "#34b385",
  600: "#2E9D74",
  700: "#278664",
  800: "#217053",
  900: "#1A5A43",
  A100: "#d5ffe3",
  A200: "#a2ffc1",
  A400: "#6fff9e",
  A700: "#56ff8d",
  contrastDefaultColor: "dark",
};

export const blue = {
  50: "#CCE0FB",
  100: "#A3C7F8",
  200: "#7AAEF5",
  300: "#5296F1",
  400: "#297DEE",
  500: "#0064EB",
  600: "#0051D9",
  700: "#0040B3",
  800: "#00308C",
  900: "#002166",
  A100: "#ffffff",
  A200: "#cdd6ff",
  A400: "#9aacff",
  A700: "#8097ff",
  contrastDefaultColor: "light",
};

export const yellow = {
  50: "#fefbf0",
  100: "#fef6da",
  200: "#fdf0c1",
  300: "#fceaa8",
  400: "#fbe695",
  500: "#fae182",
  600: "#f9dd7a",
  700: "#f9d96f",
  800: "#f8d565",
  900: "#f6cd52",
  A100: "#ffffff",
  A200: "#ffffff",
  A400: "#fffbef",
  A700: "#fff4d5",
  contrastDefaultColor: "light",
};
