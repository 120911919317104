import { Close } from "@mui/icons-material";
import { Alert, Button, IconButton, Snackbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { stateCategoryMap } from "../../constants/state";
import { useGlobalState } from "../../state";
import { GlobalSnackbar } from "../../types/interfaces";

interface SnackbarMessage {
  key: number;
  message: string;
  secondaryButton?: string;
  variant: "error" | "success" | "warning" | "info" | "simple";
}

interface SnackbarWrapperProps {
  children: React.ReactNode;
}

function SnackbarWrapper(props: SnackbarWrapperProps) {
  const { children } = props;
  const [globalSnackbar, setGlobalSnackbar] = useGlobalState(
    stateCategoryMap.GLOBAL_SNACKBAR
  );
  const [open, setOpen] = useState(false);
  const [snackPack, setSnackPack] = useState<readonly SnackbarMessage[]>([]);
  const [messageInfo, setMessageInfo] = useState<SnackbarMessage | undefined>(
    undefined
  );

  useEffect(() => {
    if (!!globalSnackbar.message) {
      pushSnack(globalSnackbar);
      setGlobalSnackbar({
        message: "",
        variant: "simple",
        secondaryButtonText: "",
      });
    }
  }, [globalSnackbar]);

  useEffect(() => {
    if (snackPack.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setOpen(true);
    } else if (snackPack.length && messageInfo && open) {
      // Close an active snack when a new one is added
      setOpen(false);
    }
  }, [snackPack, messageInfo, open]);

  function pushSnack(snack: GlobalSnackbar) {
    setSnackPack((prev) => [
      ...prev,
      {
        message: snack.message,
        key: new Date().getTime(),
        variant: snack.variant,
        secondaryButton: snack.secondaryButtonText,
      },
    ]);
  }

  function handleClose(_: React.SyntheticEvent | Event, reason?: string) {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  }

  function handleExited() {
    setMessageInfo(undefined);
  }

  return (
    <>
      {messageInfo?.variant === "simple" ? (
        <Snackbar
          style={{ width: "288px" }}
          key={messageInfo ? messageInfo.key : undefined}
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          TransitionProps={{ onExited: handleExited }}
          message={messageInfo ? messageInfo.message : undefined}
          action={
            <>
              {messageInfo?.secondaryButton && (
                <Button color="secondary" size="small" onClick={handleClose}>
                  {messageInfo?.secondaryButton}
                </Button>
              )}
              <IconButton
                aria-label="close"
                color="inherit"
                sx={{ p: 0.5 }}
                onClick={handleClose}
              >
                <Close />
              </IconButton>
            </>
          }
        />
      ) : messageInfo?.variant ? (
        <Snackbar
          key={messageInfo ? messageInfo.key : undefined}
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          TransitionProps={{ onExited: handleExited }}
        >
          <Alert style={{ width: "300px" }} severity={messageInfo.variant} onClose={handleClose}>
            {messageInfo.message}
          </Alert>
        </Snackbar>
      ) : null}
      {children}
    </>
  );
}

export default SnackbarWrapper;
