import InfoIcon from "@mui/icons-material/InfoOutlined";
import { Tooltip, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const TooltipIcon = styled(Tooltip)`
  color: ${(props) => props.theme.palette.grey[500]} !important;
`;

interface RATooltipIconProps {
  description: string;
  fontSize?: "inherit" | "small" | "medium" | "large";
}

export default function RATooltipIcon(props: RATooltipIconProps) {
  const { description } = props;

  return (
    <TooltipIcon
      placement="right"
      title={
        <Typography
          variant="caption"
          dangerouslySetInnerHTML={{ __html: description }}
          style={{ whiteSpace: "pre-line" }}
        ></Typography>
      }
    >
      <InfoIcon fontSize={props.fontSize || "medium"} />
    </TooltipIcon>
  );
}
