import {
  AccessTime,
  DateRange,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import {
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabelProps,
  TextFieldProps,
} from "@mui/material";
import React, {
  HTMLInputTypeAttribute,
  useEffect,
  useRef,
  useState,
} from "react";
import { FlexGrid, StyledTextField, TooltipFrame } from "../../layouts/Body";
import RATooltipIcon from "../RATooltipIcon";

type RATextFieldProps = TextFieldProps & {
  InputLabelProps?: InputLabelProps;
  greyText?: boolean;
  helperText?: string;
  inputLabel?: string;
  style?: any;
  tooltipText?: string;
  value?: any;
};

export function RATextField(props: RATextFieldProps) {
  const {
    InputProps,
    greyText,
    onChange,
    onBlur,
    tooltipText,
    type,
    value,
    ...rest
  } = props;
  const [internalValue, setInternalValue] = useState<any>(value);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setInternalValue(props.value);
  }, [props.value]);

  function onChangeInternal(e: React.ChangeEvent<HTMLInputElement>) {
    if (props.onChange) {
      props.onChange(e);
    } else {
      setInternalValue(e.currentTarget.value);
    }
  }

  function onBlurInternal(e: React.FocusEvent<HTMLInputElement>) {
    if (type === "number" && onChange) {
      if (!!props.inputProps?.min || props.inputProps?.min === 0) {
        e.target.value = Math.max(
          props.inputProps.min,
          Number(e.target.value)
        ).toString();
      }
      if (!!props.inputProps?.max || props.inputProps?.max === 0) {
        e.target.value = Math.min(
          props.inputProps.max,
          Number(e.target.value)
        ).toString();
      }
      onChange(e);
    }
    if (onBlur) {
      onBlur(e);
      setInternalValue(e.target.value);
    }
  }

  function handleType(type?: HTMLInputTypeAttribute) {
    if (type === "password" && showPassword) {
      return "string";
    }
    return type || "string";
  }

  function handleDateTimeIconClick() {
    if (inputRef.current) {
      inputRef.current.showPicker();
    }
  }

  return (
    <Grid container alignItems="flex-end" justifyContent="flex-start">
      <FlexGrid item xs>
        <FormControl required={props.required} style={{ width: "100%" }}>
          <StyledTextField
            inputRef={inputRef}
            title=""
            greytext={!!greyText ? greyText.toString() : "false"}
            onBlur={onBlurInternal}
            onChange={onChangeInternal}
            type={handleType(type)}
            value={!!props.onChange ? value : internalValue}
            InputProps={
              props.type === "password"
                ? {
                    endAdornment: !props.disabled && (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          disabled={props.disabled}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }
                : props.type === "date"
                ? {
                    endAdornment: !props.disabled && (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleDateTimeIconClick}
                          disabled={props.disabled}
                        >
                          <DateRange fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }
                : props.type === "time"
                ? {
                    endAdornment: !props.disabled && (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleDateTimeIconClick}
                          disabled={props.disabled}
                        >
                          <AccessTime fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }
                : InputProps
            }
            {...rest}
          />
        </FormControl>
        {tooltipText && (
          <TooltipFrame
            bottompadding={
              !!props.helperText ? props.helperText.toString() : "false"
            }
          >
            <RATooltipIcon fontSize="medium" description={tooltipText} />
          </TooltipFrame>
        )}
      </FlexGrid>
    </Grid>
  );
}
