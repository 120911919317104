import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import {
  LinearProgress,
  PaletteMode,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import * as Sentry from "@sentry/react";
import { createUploadLink } from "apollo-upload-client";
import React, { Suspense, useEffect, useState } from "react";
import { I18nextProvider } from "react-i18next";
import { BrowserRouter } from "react-router-dom";
import { useAuth0 } from "./auth0/ReactAuth0Wrapper";
import { API_URL } from "./config";
import { sentryInit } from "./constants/sentry";
import i18n from "./i18n";
import { FlexGrid } from "./layouts/Body";
import ErrorWrapper from "./layouts/Wrappers/ErrorWrapper";
import LoadingWrapper from "./layouts/Wrappers/LoadingWrapper";
import SnackbarWrapper from "./layouts/Wrappers/SnackbarWrapper";
import AppRouter from "./routes/AppRouter";
import { getDesignTokens } from "./style/theme";
import { consoleError } from "./utils/logs";

const paletteMode = localStorage.getItem("paletteMode");

const App: React.FC = () => {
  const { getTokenSilently } = useAuth0();
  const [authToken, setAuthToken] = useState();
  const [mode] = React.useState<PaletteMode>(
    (paletteMode as PaletteMode) || "light"
  );

  useEffect(() => {
    // Initialize Sentry with the configuration from constants/sentry.ts. This must be done as early as possible.
    Sentry.init(sentryInit);
  }, []);

  const theme = React.useMemo(
    () =>
      createTheme({
        ...getDesignTokens(mode),
      }),
    [mode]
  );

  useEffect(() => {
    document.body.classList.toggle("dark-mode", mode === "dark");
  }, [mode]);

  const callApi = async () => {
    try {
      return await getTokenSilently();
    } catch (error) {
      consoleError(error);
    }
  };

  const client = new ApolloClient({
    uri: API_URL,
    cache: new InMemoryCache({ addTypename: false }),
    defaultOptions: {
      query: {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
      },
    },
    link: createUploadLink({
      uri: API_URL,
      fetch: (uri: any, options: any) => {
        options.headers.Authorization = `Bearer ${authToken}`;
        return fetch(uri, options);
      },
    }),
  });

  callApi()
    .then((result) => {
      setAuthToken(result);
    })
    .catch((error) => {
      consoleError(error);
    });

  return (
    <Suspense fallback={<LinearProgress />}>
      <I18nextProvider i18n={i18n}>
        <ThemeProvider theme={theme}>
          <ApolloProvider client={client}>
            <ErrorWrapper>
              <FlexGrid height={"100vh"}>
                <LoadingWrapper>
                  <SnackbarWrapper>
                    <BrowserRouter>
                      <AppRouter authToken={authToken} />
                    </BrowserRouter>
                  </SnackbarWrapper>
                </LoadingWrapper>
              </FlexGrid>
            </ErrorWrapper>
          </ApolloProvider>
        </ThemeProvider>
      </I18nextProvider>
    </Suspense>
  );
};

export default Sentry.withProfiler(App);
