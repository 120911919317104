import {
  Divider,
  Grid,
  GridProps,
  Stack,
  StackProps,
  TextField,
  Typography,
  TypographyProps,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import RATooltipIcon from "../components/RATooltipIcon";

interface ContentStackProps extends StackProps {
  disablePadding?: boolean;
  disableDividers?: boolean;
}

export const ContentStack = (props: ContentStackProps) => {
  const { disablePadding, disableDividers, padding, ...rest } = props;
  let internalPadding = padding;
  if (disablePadding) {
    internalPadding = 0;
  } else if (!padding) {
    internalPadding = 4;
  }
  return (
    <Stack
      title=""
      divider={
        disableDividers ? null : <Divider flexItem variant="fullWidth" />
      }
      padding={internalPadding}
      sx={
        disablePadding
          ? { width: "100%" }
          : {
              maxWidth: "100%",
            }
      }
      {...rest}
    >
      {props.children}
    </Stack>
  );
};

interface AccordionContentStackProps extends StackProps {
  disableDividers?: boolean;
}

export const AccordionContentStack = (props: AccordionContentStackProps) => {
  const { padding, ...rest } = props;
  return (
    <Stack
      title=""
      divider={
        props.disableDividers ? null : <Divider flexItem variant="fullWidth" />
      }
      padding={!!padding || padding === 0 ? padding : 4}
      {...rest}
    >
      {props.children}
    </Stack>
  );
};

interface FormSectionGridProps extends GridProps {
  title?: string;
  tooltipText?: string;
}

export const FormSectionGrid = (props: FormSectionGridProps) => {
  const { spacing, title, ...rest } = props;
  return (
    <>
      {title && (
        <FormSectionLabel zIndex={1}>
          {title}
          {props.tooltipText && (
            <RATooltipIcon fontSize="small" description={props.tooltipText} />
          )}
        </FormSectionLabel>
      )}
      <Grid
        container
        spacing={!!spacing || spacing === 0 ? spacing : 6}
        direction="column"
        alignItems="flex-start"
        {...rest}
      >
        {props.children}
      </Grid>
    </>
  );
};

interface FormSectionStackProps extends StackProps {
  title?: string;
  tooltipText?: string;
}

export const FormSectionStack = (props: FormSectionStackProps) => {
  const { spacing, title, ...rest } = props;
  return (
    <>
      {title && (
        <FormSectionLabel zIndex={1}>
          {title}
          {props.tooltipText && (
            <RATooltipIcon fontSize="small" description={props.tooltipText} />
          )}
        </FormSectionLabel>
      )}
      <Stack
        direction="column"
        alignItems="flex-start"
        spacing={!!spacing || spacing === 0 ? spacing : 6}
        {...rest}
      >
        {props.children}
      </Stack>
    </>
  );
};

export const FormSectionLabel = (props: TypographyProps) => {
  return <Typography marginBottom={4} variant="subtitle2" {...props} />;
};

export const ButtonGrid = (props: StackProps) => {
  const { spacing, padding, ...rest } = props;
  return (
    <>
      <Divider variant="fullWidth" style={{ margin: "0" }} />
      <Stack
        title=""
        alignItems="center"
        direction="row"
        flex="1"
        justifyContent="flex-end"
        padding={!!padding || padding === 0 ? padding : 4}
        spacing={!!spacing || spacing === 0 ? spacing : 3}
        {...rest}
      >
        {props.children}
      </Stack>
    </>
  );
};

export const ButtonGridFab = (props: StackProps) => {
  const { spacing, padding, ...rest } = props;
  return (
    <div
      style={{
        width: "-webkit-fill-available",
        position: "sticky",
        bottom: 0,
      }}
    >
      <Stack
        title=""
        alignItems="center"
        direction="row"
        flex="1"
        justifyContent="flex-end"
        padding={!!padding || padding === 0 ? padding : 6}
        spacing={!!spacing || spacing === 0 ? spacing : 3}
        {...rest}
      >
        {props.children}
      </Stack>
    </div>
  );
};

export const FlexGrid = styled(Grid)`
  display: flex;
`;

export const MarginBottomGrid = styled(Grid)`
  margin-bottom: 20px !important;
`;

export const CenteredGrid = styled(FlexGrid)`
  align-items: center;
  justify-content: center;
`;

export const TextFrame = styled("div")`
  display: flex;
  width: 600px;
`;

export const TitleFrame = styled("div")`
  display: flex;
  width: 100%;
  padding: 0 0 25px;
`;

export const RefreshFrame = styled("div")`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

export const NoUnitsFrame = styled("div")`
  border-style: dashed;
  border-width: 1px;
  padding: 10px;
  border-color: ${(props) => props.theme.palette.grey[600]};
`;

export const StyledTextField = styled(TextField)<{ greytext?: string }>`
  .MuiInputBase-root {
    color: ${(props) =>
      props.greytext === "true"
        ? props.theme.palette.grey[600]
        : props.theme.palette.primary.main} !important;
  }
`;

export const TooltipFrame = styled("div")<{ bottompadding?: string }>`
  display: flex;
  align-items: center;
  margin: 0 0 0 12px;
  padding: ${(props) => (props.bottompadding !== "false" ? "0 0 23px 0" : "0")};
`;

interface PdfExportWrapperProps {
  exportingPdf: boolean;
}

export const PdfExportWrapper = styled("div")<PdfExportWrapperProps>`
  padding: ${({ exportingPdf }) => (exportingPdf ? "40px" : "0")};
  background: ${(props) => props.theme.palette.background.default};
`;
