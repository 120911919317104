import {
  BrowserOptions,
  browserTracingIntegration,
  replayIntegration,
} from "@sentry/react";
import { isEnvironment } from "../utils/utils";
import { environments } from "./constants";

export const sentryInit: BrowserOptions = {
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [browserTracingIntegration(), replayIntegration()],
  enabled:
    isEnvironment(environments.DEV) ||
    isEnvironment(environments.STAGING) ||
    isEnvironment(environments.PROD),
  environment: import.meta.env.VITE_ENV,
  // Performance Monitoring
  tracesSampleRate: isEnvironment(environments.PROD) ? 0.1 : 0.5, //  Capture 10% of the transactions for prod and 50% for others
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "https://libry-admin-dev.redia.dk",
    "https://libry-admin-staging.redia.dk",
    "https://libry-admin.redia.dk",
  ],
  // Session Replay
  replaysSessionSampleRate: isEnvironment(environments.PROD) ? 0.1 : 1.0, // Sample rate set at 10% for prod and 100% for others
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
};
