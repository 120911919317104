import { Cancel, ContentCopy, Save, Undo } from "@mui/icons-material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Button, ButtonProps, Fab, FabProps, styled } from "@mui/material";

export const CancelButton = (props: ButtonProps) => (
  <Button variant="outlined" {...props} />
);

export const ConfirmButton = (props: ButtonProps) => (
  <Button variant="contained" {...props} />
);

interface FabWithLabelProps extends FabProps {
  label: string;
  hideIcon?: boolean;
}

export const CancelButtonFab = (props: FabWithLabelProps) => (
  <Fab variant="extended" size="medium" {...props}>
    {!props.hideIcon && <Undo sx={{ mr: 1 }} style={{ color: "inherit" }} />}
    {props.label}
  </Fab>
);

export const ConfirmButtonFab = (props: FabWithLabelProps) => (
  <Fab variant="extended" size="medium" color="primary" {...props}>
    {!props.hideIcon && <Save sx={{ mr: 1 }} style={{ color: "inherit" }} />}
    {props.label}
  </Fab>
);

export const DeleteButton = (props: ButtonProps) => (
  <Button
    color="error"
    variant="text"
    startIcon={<DeleteOutlineIcon />}
    {...props}
  />
);

export const DuplicateButton = (props: ButtonProps) => (
  <Button variant="text" startIcon={<ContentCopy />} {...props} />
);

interface ExportButtonProps extends ButtonProps {
  height: string | number;
}

export const ExportButton = (props: ExportButtonProps) => (
  <Button
    variant="outlined"
    sx={{ opacity: 0.5, height: props.height ? props.height : "unset" }}
    {...props}
  />
);

export const DeleteIconButton = styled(Cancel)<{ disabled?: boolean }>`
  color: ${(props) =>
    props.disabled ? props.theme.palette.grey[500] : "unset"};
  cursor: ${(props) => (!props.disabled ? "pointer" : "unset")};
`;

export const ServiceLinkButton = styled(Button)`
  width: 328px;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  padding: 0 16px;
  text-transform: none;
`;
