import {
  AUTH0_AUDIENCE,
  AUTH0_CLIENT_ID,
  AUTH0_TENANT_DOMAIN,
} from "../config";
import { Auth0Provider } from "./ReactAuth0Wrapper";

const onRedirectCallback = (appState: any) => {
  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

function AuthProvider(props: any) {
  return (
    <Auth0Provider
      domain={AUTH0_TENANT_DOMAIN}
      client_id={AUTH0_CLIENT_ID}
      redirect_uri={window.location.origin}
      onRedirectCallback={() => onRedirectCallback}
      audience={AUTH0_AUDIENCE}
    >
      {props.children}
    </Auth0Provider>
  );
}

export default AuthProvider;
