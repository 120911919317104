import { isEmpty } from "lodash";
import config from "../auth0/auth_config.json";
import { useAuth0 } from "../auth0/ReactAuth0Wrapper";
import { stateCategoryMap } from "../constants/state";
import { useGlobalState } from "../state";
import { RoleLevel } from "../types/authentication";
import { isPermitted } from "../utils/authorization";

export function useIsPermitted(roleLevel: RoleLevel) {
  const { user } = useAuth0();
  const [roleLevels] = useGlobalState(stateCategoryMap.ROLE_LEVELS);
  const completed: boolean = !isEmpty(roleLevels) && !!user;
  return {
    isPermitted: isPermitted(user, roleLevels, roleLevel),
    completed: completed,
  };
}

export function useHasNoRoles() {
  const { user } = useAuth0();
  if (!user || !user[config.roleUrl] || isEmpty(user[config.roleUrl])) {
    return true;
  } else {
    return false;
  }
}

export function usePartnerId() {
  const { user } = useAuth0();
  if (!user || !user[config.partnerUrl]) {
    return "";
  }
  return String(user[config.partnerUrl]);
}
