export const BACKEND_URL = import.meta.env.VITE_BACKEND;
export const ASSET_BACKEND = import.meta.env.VITE_ASSET_BACKEND;
export const FRONTEND_URL = import.meta.env.VITE_FRONTEND;
export const ENV_INDICATOR = import.meta.env.VITE_ENV;
export const API_URL = `${BACKEND_URL}/query`;
export const ASSET_URL = `${BACKEND_URL}/asset/`;
export const ENV = import.meta.env.VITE_ENV;
export const BUILD_NUMBER = import.meta.env.VITE_VERSION || "local";
export const AUTH0_TENANT_DOMAIN = import.meta.env.VITE_AUTH0_TENANT_DOMAIN;
export const AUTH0_CLIENT_ID = import.meta.env.VITE_AUTH0_CLIENT_ID;
export const AUTH0_REDIRECT_URL = import.meta.env.VITE_AUTH0_REDIRECT_URL;
export const AUTH0_AUDIENCE = import.meta.env.VITE_AUTH0_AUDIENCE;
export const GA4_MEASUREMENT_ID = import.meta.env.VITE_GA4_MEASUREMENT_ID;
export const INSPIRE_BASE_URL = import.meta.env.VITE_INSPIRE_BASE_URL;
