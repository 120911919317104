import { styled } from "@mui/material/styles";
import React from "react";
import Header from "../../components/Header";

const PageFrame = styled("div")`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.palette.background.default};
`;
const MarginFrame = styled("div")`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.palette.background.default};
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    padding: 56px 16px 0px 16px;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    padding: 56px 32px 0px 32px;
  }
  ${(props) => props.theme.breakpoints.between("md", "lg")} {
    padding: 56px 64px 0px 64px;
  }
  ${(props) => props.theme.breakpoints.between("lg", "xl")} {
    padding: 56px 120px 0px 120px;
  }
  ${(props) => props.theme.breakpoints.up("xl")} {
    padding: 56px 0 0 0;
    align-items: center;
  }
`;
interface PageWrapperProps {
  children: React.ReactNode;
  isAuthenticated: boolean;
  hideHeader: boolean;
}

function PageWrapper(props: PageWrapperProps) {
  const { children, hideHeader, isAuthenticated } = props;

  return (
    <PageFrame>
      {!hideHeader && <Header />}
      {isAuthenticated ? <MarginFrame>{children}</MarginFrame> : children}
    </PageFrame>
  );
}

export default PageWrapper;
