import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-locize-backend";
import { locizePlugin } from "locize";
import { initReactI18next } from "react-i18next";
import { languageIsos } from "../constants/Languages";
import { environments } from "../constants/constants";

const isProduction = import.meta.env.VITE_ENV === environments.PROD;

const locizeOptions = {
  projectId: import.meta.env.VITE_LOCIZE_PROJECT_ID,
  refLng: import.meta.env.VITE_LOCIZE_REF_LNG,
  version: import.meta.env.VITE_LOCIZE_VERSION,
};

i18n
  .use(locizePlugin)
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: languageIsos.en,
    interpolation: {
      escapeValue: false, // not needed for react as it escaped by default
    },
    saveMissing: !isProduction,
    backend: locizeOptions,
    react: {
      bindI18n: "languageChanged editorSaved",
      nsMode: "default",
    },
  });

export default i18n;
