import {
  Backdrop,
  BackdropProps,
  CircularProgress,
  Theme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  backdrop: {
    zIndex: 10000,
    color: "#fff",
  },
}));

export function RABackdrop(props: BackdropProps) {
  const classes = useStyles();
  return (
    <Backdrop className={classes.backdrop} open={props.open}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
