import { gql } from "@apollo/client";

export const customerQuery = gql`
  query ($customerId: ID!) {
    customer(id: $customerId) {
      configMap
      country
      id
      name
      partnerId
      products {
        type
        enabled
      }
    }
  }
`;

export const allCustomersQueryWithConfigs = gql`
  {
    allCustomers(params: { limit: 500 }) {
      configMap
      id
      name
    }
  }
`;

export const allCustomersQuery = gql`
  {
    allCustomers(params: { limit: 500 }) {
      id
      name
    }
  }
`;

export const allCustomersForPartnerQuery = gql`
  {
    allCustomersForPartner(params: { limit: 200 }) {
      id
      name
      partnerId
    }
  }
`;

export const getCustomerGroupsQuery = gql`
  {
    getCustomerGroups(params: { limit: 200 }) {
      id
      name
      translationKey
    }
  }
`;

export const allCustomersConfigProductPartnerQuery = gql`
  {
    allCustomers(params: { limit: 1000 }) {
      country
      id
      name
      partnerId
      products {
        type
        enabled
      }
      configMap
    }
  }
`;
