import { isEmpty } from "lodash";
import config from "../auth0/auth_config.json";
import { AuthZeroRole, RoleLevel, RoleLevels } from "../types/authentication";
import { EMPLOYEE, NONE, roleLevelMap } from "../constants/authentication";

export function hasRole(user: any, requiredRoles: string[]) {
  if (!user || !user[config.roleUrl] || isEmpty(user[config.roleUrl])) {
    return false;
  }
  return requiredRoles.some((role) => user[config.roleUrl].includes(role));
}

export function isPermitted(
  user: any,
  roleLevels: RoleLevels,
  requiredRoleLevel: RoleLevel
): boolean {
  if (!user || !user[config.roleUrl] || isEmpty(user[config.roleUrl])) {
    return false;
  }
  const userRoles = user[config.roleUrl];
  for (let index = 0; index < userRoles.length; index++) {
    if (
      !!roleLevels[userRoles[index]] &&
      roleLevels[userRoles[index]] >= requiredRoleLevel
    ) {
      return true;
    }
  }
  return false;
}

export function getUserRoles(user: any) {
  if (!user || !user[config.roleUrl] || isEmpty(user[config.roleUrl])) {
    return [];
  }
  return user[config.roleUrl];
}

export function getHighestRole(userRoles: AuthZeroRole[]): AuthZeroRole | null {
  let highestRole: AuthZeroRole | null = null;
  userRoles.forEach((role) => {
    if (!highestRole || roleLevelMap[role.name.toLowerCase()] > roleLevelMap[highestRole.name.toLowerCase()]) {
      highestRole = role;
    }
  });
  return highestRole;
}
